<script setup>
import AppTable from '@components/AppTable.vue'
import { computed, ref } from 'vue'
import { defaultTableParams } from '@utils/app-table'
import request from '@utils/request'
import { useI18n } from 'vue-i18n-bridge'
const { t } = useI18n()
const props = defineProps({
  customerCompanyId: {
    type: String
  }
})

const tableHeaders = computed(() => {
  const ret =
    props.customerCompanyId == null
      ? [
          {
            text: t('fields.customerCompany'),
            value: 'customer_company_id.name'
          }
        ]
      : []
  ret.push(
    ...[
      {
        text: t('fields.date'),
        value: 'date'
      },
      {
        text: t('fields.usableUserCount'),
        value: 'usable_user_count'
      },
      {
        text: t('fields.totalStorage'),
        value: 'total_available_storage_gb'
      },
      {
        text: t('fields.data'),
        value: 'db_storage_gb'
      },
      {
        text: t('fields.file'),
        value: 'file_storage_gb'
      },
      {
        text: t('fields.totalUsed'),
        value: 'total_used_storage_gb'
      }
    ]
  )
  return ret
})

const loading = ref(false)
const count = ref(0)
const items = ref([])

const getItems = async (options) => {
  loading.value = true
  try {
    let params = defaultTableParams(options)
    let url = 'customer-company/customer_company_storage/'
    if (props.customerCompanyId) {
      url += `?customer_company_id=${props.customerCompanyId}`
    }
    const { data } = await request({
      method: 'get',
      url: url,
      params: params
    })
    items.value = data.results
    count.value = data.count
  } catch (e) {
    console.log(e)
  } finally {
    loading.value = false
  }
}
</script>

<template>
  <div>
    <v-toolbar flat>
      <v-toolbar-title>Subscriptions</v-toolbar-title>
      <v-divider class="mx-4" inset vertical></v-divider>
    </v-toolbar>
    <v-card flat>
      <v-card-text>
        <app-table
          server-side
          app="customer_company"
          model="customercompanystorage"
          :headers="tableHeaders"
          hide-edit
          hide-delete
          :server-items="items"
          :server-items-length="count"
          @server="getItems"
        >
        </app-table>
      </v-card-text>
    </v-card>
  </div>
</template>

<style scoped lang="scss"></style>
